import globalConfig from "../config";
import * as SignalR from '@microsoft/signalr';

export default class SocketClient
{
    /**
     * 链接地址
     * @private
     * @return string
     */
    private url: string;

    /**
     * 授权token
     * @private
     * @return string
     */
    private token: string;

    /**
     * 授权token
     * @private
     * @return string
     */
    private socket: SignalR.HubConnection;

    /**
     * 构造函数
     * @param connection 链接串
     * @param token 授权串
     */
    constructor (url?: string, token?: string)
    {
        this.url = url || globalConfig.signalrUrl;

        this.token = token || JSON.parse(localStorage.getItem("token")).accessToken;
        this.connect();
    }

    /**
     * 链接
     * @param connection 链接串
     * @param token 授权串
     */
    private async connect()
    {
        if (!this.token || !this.url) {
            throw new Error('[Vue-SignalR] Cannot locate signalr-client')
        }

        new Promise((resove, reject)=>
        {
            // 组件重新加载时, 如果 socket 存在, 不需要新建
            if (!this.socket)
            {
                this.socket = new SignalR.HubConnectionBuilder()
                .configureLogging(SignalR.LogLevel.Information)
                .withUrl(this.url, {
                    accessTokenFactory: () => this.token,
                    skipNegotiation: false,
                    transport: SignalR.HttpTransportType.WebSockets
                }).build();
                this.socket.keepAliveIntervalInMilliseconds = 30 * 1000;
                this.socket.serverTimeoutInMilliseconds = 10 * 60 * 1000;
            }
            // console.log(111)
            // this.socket.start().then(()=>
            // {
            //     console.log(555)
            //     resove(null)
            //     console.log(555)
            // });
            // console.log(22222)
        })
        
    }
    
    /**
     * 发送事件
     * @static
     * @property
     * @returns GenService
     */
    public send(eventName, agrs)
    {
      
        this.socket.send(eventName, agrs);
    }

    /**
     * 发送事件
     * @static
     * @property
     * @returns GenService
     */
    public on(eventName, agrs)
    {
        this.socket.on(eventName, agrs);
    }

}