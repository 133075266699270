import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ba3aa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-content hea-row hea-justifyC" }
const _hoisted_2 = {
  key: 0,
  class: "hea-justifyC hea-flex-item1 title"
}
const _hoisted_3 = {
  key: 1,
  class: "hea-justifyC hea-flex-item1 title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.go(-1)))
    }, [
      _createVNode(_component_van_icon, { name: "arrow-left" })
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.routeName), 1)),
    (_ctx.showHome)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "home",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push({name: 'home'})))
        }, [
          _createVNode(_component_van_icon, { name: "wap-home-o" })
        ]))
      : _createCommentVNode("", true)
  ]))
}