import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 登录授权相关服务
 * @class
 * @version 1.0.0
 */
export default class AuthService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return TagService
     */
     private static _instance: AuthService;

    /**
     * 实例
     * @static
     * @property
     * @returns TagService
     */
    public static get instance(): AuthService
    {
        if(!this._instance)
        {
            this._instance = new AuthService();
        }
        
        return this._instance;
    }

    /**
     * 登录
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async login(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.login({ 
            data: data 
        });
        return response;
    }

    /**
     * 人脸核身
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async faceVerifyLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.faceVerifyLogin({ 
            data: data 
        });
        return response;
    }

    /**
     * 查询人脸核审结果
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async selectFaceVerify(orderNo: any): Promise<IHttpResponse>
    {
        let response = await this.apis.selectFaceVerify({ 
            params: {
                orderNo
            } 
        });
        return response;
    }

    /**
     * 刷新在线时长
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async refreshAuth(): Promise<IHttpResponse>
    {
        let response = await this.apis.refreshAuth({
            loading: false
        });
        return response;
    }
}