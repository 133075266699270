import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 我的任务相关服务
 * @class
 * @version 1.0.0
 */
export default class MemberService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return MemberService
     */
     private static _instance: MemberService;

    /**
     * 实例
     * @static
     * @property
     * @returns MemberService
     */
    public static get instance(): MemberService
    {
        if(!this._instance)
        {
            this._instance = new MemberService();
        }
        
        return this._instance;
    }

    /**
     * 注册
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async register(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.register({ 
            data: data 
        });
        
        return response;
    }

    /**
     * 修改密码
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async updatePassword(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.updatePassword({ 
            data: data 
        });
        
        return response;
    }

    /**
     * 客户端获取核保任务数据
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getTaskInfo(): Promise<IHttpResponse>
    {
        let response = await this.apis.getTaskInfo({ });
        
        return response;
    }

    /**
     * 客户端获取核保人员数据
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getMembers(hasTask?: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getMembers({ 
            params: {
                hasTask
            }
        });
        
        return response;
    }

    /**
     * 获取我的问卷任务分页
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getQuestionnaireTaskPage(data: any, loading: boolean = true): Promise<IHttpResponse>
    {
        let response = await this.apis.getQuestionnaireTaskPage({ 
            data,
            loading: loading
        });
        
        return response;
    }

    /**
     * 获取我的体检任务分页
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getPhysicalTaskPage(data: any, loading: boolean = true): Promise<IHttpResponse>
    {
        let response = await this.apis.getPhysicalTaskPage({ 
            data,
            loading: loading
        });
        
        return response;
    }

    
}