import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';

// 使用setup模式定义
export const useUserInfo = defineStore('userInfo', () => {
    // token信息
    let userInfo = ref<any>({});

    function setUserInfo(data: any)
    {
        userInfo.value = data;
        localStorage.setItem("userInfo", JSON.stringify(data))
    }
    
    function getUserInfo()
    {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    return { userInfo, setUserInfo, getUserInfo };

},{
    // persist: {
    //     enabled: true,
    //     strategies: [
    //       {
    //         storage: localStorage,
    //         paths: ["userInfo"]
    //       }
    //     ]
    // }
});