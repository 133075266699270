
import { computed, defineComponent, watch, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
    props:
    {
        // 页面标题
        title:{
            type: String,
        },
        // 页面跳转路由名称
        pathName:{
            type: String
        },
        // 页面跳转路由名称
        showHome:{
            type: Boolean,
            default:()=>{(true)}
        }
    },
    setup(props){
        const router = useRouter();
        const route = useRoute();
        let state = reactive({
            routeName:""
        })

        onMounted(()=>
        {
            state.routeName = route && route.meta && (route.meta.pageName as string) || "友邦核保体检平台";
        })
        // 返回
        const goBack=()=>
        {
            if(props.pathName)
            {
                let pathName = (props.pathName as string);
                router.push({name: pathName});
            }
            else
            {
                router.go(-1);
            }
        }

        return{
            ...toRefs(state),
            goBack,
            router
        }
    }
})

