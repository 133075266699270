import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 问卷任务相关服务
 * @class
 * @version 1.0.0
 */
export default class QuestionnaireService extends ServiceBase
{

    /**
     * 实例
     * @private 
     * @return QuestionnaireService
     */
     private static _instance: QuestionnaireService;

    /**
     * 实例
     * @static
     * @property
     * @returns QuestionnaireService
     */
    public static get instance(): QuestionnaireService
    {
        if(!this._instance)
        {
            this._instance = new QuestionnaireService();
        }
        
        return this._instance;
    }

    /**
     * 获取问卷列表
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getQuestionnaireList(taskId: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getQuestionnaireList({ 
           params:{taskId}
        });
        
        return response;
    }
    
    /**
     * 获取问卷签名
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async questionnaireTaskSign(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.questionnaireTaskSign({ 
           data
        });
        
        return response;
    }

    /**
     * 记录问卷任务次数
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async questionnaireTaskCalling(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.questionnaireTaskCalling({ 
           params:{id}
        });
        
        return response;
    }

    /**
     * 获取视频图片
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getVideoPhoth(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getVideoPhoth({ 
           params:{id}
        });
        
        return response;
    }


}