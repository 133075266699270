import Type from "./type";
import dayjs from "dayjs";

/**
 * 替换字符串占位符
 * @function
 * @version 1.0.0
 * @param {string} str 传入需要处理的字符串
 * @param {Array | Object} args 传入字符串中要被替换的参数。类型为数组或者object
 * @returns string
 */
export function formatString(str: string, args: Array<any> | any): string
{
    let tempStr = str;

    if (arguments.length > 1)
    {
        if (Type.isObject(args))
        {
            for (let key in args)
            {
                if (args[key] !== undefined)
                {
                    let reg = new RegExp("({" + key + "})", "g");
                    tempStr = tempStr.replace(reg, args[key]);
                }
            }
        }

        if (Type.isArray(args))
        {
            for (let i = 0; i < args.length; i++)
            {

                if (args[i] !== undefined)
                {
                    let reg = new RegExp("({)" + i + "(})", "g");
                    tempStr = tempStr.replace(reg, args[i]);
                }
            }
        }
    }
    return tempStr;
}

/**
 * 根据过滤条件的数据类型编码条件值。
 * @param  {IFilter} filter 过滤条件实例。
 * @returns string 编码后的字符串。
 */
export function encodeFilterValue(filter: any): string
{
    // 如果没有设置类型，则默认做字符串处理
    let filterType = filter.type || String;
    let filterValue = "";
    
    if(filterType === Array || filterType === Object)
    {
        // 如果是数组或者JSON对象，则序列化处理
        filterValue = JSON.stringify(filter.value);
    }
    else
    {
        // 其他数据类型强制转换为字符串
        if(!Type.isEmptyObject(filter.value))
        {
            filterValue = String(filter.value);
        }
    }
    
    if(filterValue)
    {
        filterValue = encodeURIComponent(filterValue);
    }

    return filterValue;
}

/**
 * 根据过滤条件的数据类型解码条件值。
 * @param  {IFilter} filter 过滤条件实例。
 * @param  {string} value 需要解码的字符串值。
 * @returns any 解码后的真实数据。
 */
export function decodeFilterValue(filter: any, value: string): any
{
    // 如果没有设置类型，则默认做字符串处理
    let filterType = filter.type || String;
    let filterValue: any;

    // 解码字符串值
    value = decodeURIComponent(value);

    switch(filterType)
    {
        case String:
        case Date:
        {
            filterValue = value;

            break;
        }
        case Number:
        {
            filterValue = parseFloat(value);

            break;
        }
        case Boolean:
        {
            filterValue = (value === "true" || value === "1");

            break;
        }
        default:
        {
            filterValue = JSON.parse(value);

            break;
        }
    }

    return filterValue;
}

/**
 * 下载文件兼容性处理。
 * @param url 下载文件的url
 */
export function downloadFileSteam(url: string, name: string = new Date().getTime().toString()): void
{
    if(!url || typeof url !== "string")
    {
        throw new Error("download url is avalid!");
    }

    const requstConfig: any =
    {
        method: "GET",
        responseType: "blob"
    };

    fetch(url, requstConfig).then(res =>
    {
        return res.blob();
    }).then(blob =>
    {
        let bl = new Blob([blob], {type: "image/png"});
        const link = window.URL.createObjectURL(bl);
        
        downloadFile(link, `${name}.${getFileType(url)}`);
        window.URL.revokeObjectURL(link);
    });
}

/**
 * 根据文件路径获取文件后缀
 * @returns string
 */
export function getFileType(filePath: string): string
{
    // 获取最后一个.的位置
    let index = filePath.lastIndexOf(".");
    if(index === -1) {
        return "";
    }
    
    return filePath.substr(index + 1);
}

/**
 * 下载文件兼容性处理。
 * @param url 下载文件的url
 */
export function downloadFile(url: string, filename?: string): void
{
    if(!url || typeof url !== "string")
    {
        throw new Error("download url is avalid!");
    }

    const linkEl: any = (document as any).createElement("a");
        
    if("download" in linkEl)
    {
        linkEl.download = filename ? filename : url.substr(url.lastIndexOf("/"));
        document.body.appendChild(linkEl);
        linkEl.href = url;
        linkEl.click();
        linkEl.remove();
    } else {
       window.open(url, "_self");
    }
}

/**
 * 获取cookie
 * @param name 需要获取的key
 */
export function getCookie(): any
{
    let cookie = document.cookie;
    // let cookie = `OUTFOX_SEARCH_USER_ID_NCOO=1297116724.720062; ___rl__test__cookies=1596681765366; ExternalLogin={"$id":"1","UserId":25753,"UserName":"MGHBSLMP0ELLQmXWuB3lRg==","UserMobile":"MGHBSLMP0ELLQmXWuB3lRg==","Relation":1,"UserSex":null,"timestamp":"1597200529488","nonce":"1438377233","signature":"47F2EB365333E651534E82B11D5762A6D1E1804BA53DE8018466CA0D6492E9BE","IsPerfect":"1","Channel":"买单吧"}`;
    let cookieArr = cookie.split("; ");
    let obj: any = {};
    cookieArr.forEach((item: any) => {
        let index = item.indexOf("=");
        let key = item.substr(0, index);
        let valueStr = item.substr(index + 1, item.length);
        let value = "";
        if(isJSON(valueStr))
        {
            value = JSON.parse(valueStr);
        }
        else
        {
            value = valueStr;
        }
        obj[key] = value;
    });

    return obj;
}
/**
 * 判断是否是json
 */
export function isJSON(str: string): boolean
{
    if (typeof str === "string")
    {
        try
        {
            let obj = JSON.parse(str);
            if(typeof obj === "object" && obj)
            {
                return true;
            }
            else
            {
                return false;
            }

        }
        catch(e)
        {
            return false;
        }
    }
    return false;
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDate(value: string | Date): string
{
    if(!Type.isEmptyObject(value))
    {
        return dayjs(value).format("YYYY/MM/DD");
    }
    
    return "";
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd hh:mm:ss` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDateTime(value: string | Date): string
{
    if(!Type.isEmptyObject(value))
    {
        return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
    }
    
    return "";
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd hh:mm:` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDateHHMM(value: string | Date): string
{
    if(!Type.isEmptyObject(value))
    {
        return dayjs(value).format("YYYY/MM/DD HH:mm");
    }
    
    return "";
}

/**
 * 手机号码校验
 * @param  {any} value
 * @returns string
 */
export function validatePhone(rule: any, value: any, callback: any): any
{
    const reg = /^[1][3-9][0-9]{9}$/;
    if(!value)
    {
        callback();
    }
    else
    {
        if ((!reg.test(value)))
        {
            callback(new Error("请输入完整手机号"));
        }
        else
        {
            callback();
        }
    }
}

/**
 * 身份证校验
 * @param  {any} value
 * @returns string
 */
export function validateIdNo(rule: any, value: any, callback: any): any
{
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if(!value)
    {
        callback();
    }
    else
    {
        if ((!reg.test(value)))
        {
            callback(new Error("请输入正确的身份证号码"));
        }
        else
        {
            callback();
        }
    }
}

/**
 * 身份证获取年龄
 * @param  {any} value
 * @returns string
 */
export function GetAgeByIDCard(identityCard: string): number
{
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!reg.test(identityCard))
    {
        return 0;
    }

    let len = (identityCard + "").length;
    let strBirthday = "";
    if (len === 18)
    {
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
    }

    if (len === 15)
    {
        strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
    }

    let birthDate = new Date(strBirthday);
    let nowDateTime = new Date();
    let age = nowDateTime.getFullYear() - birthDate.getFullYear();
    // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
    if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate()))
    {
        age--;
    }

    return age;
}

/**
 * 身份证获取性别
 * @param  {any} value
 * @returns string
 */
export function GetSexByIDCard(identityCard: string): string
{
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!reg.test(identityCard))
    {
        return "";
    }

    return parseInt(identityCard.substr(16, 1)) % 2 === 1 ? "男" : "女";
}

/**
 * 判断时间
 * @returns 
 */
export function timeFix () {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

/**
 * 触发 window.resize
 */
 export function triggerWindowResizeEvent () {
    const event: any = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}

/**
 * 函数节流，在监听频繁触发函数（scroll, touchmove, mouseover等）时调用，
 * @param {Function} func 
 * @param {Numebr} wait 
 * @param {Number} mustRun 
 */
export const throttle = function(func: Function, wait: number) {
    let timeout;
    return function() {
        let context = this;
        let args = arguments;
        if (!timeout) {
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        }

    };
}

/*
* fn [function] 需要防抖的函数
* delay [number] 毫秒，防抖期限值
*/
export function debounce(func, wait) {
    let timer;
    return function (...args) {
        if (timer) {
        clearTimeout(timer)
        }
        timer = setTimeout(() => {
        func.apply(this, args)
        }, wait)
    }
}