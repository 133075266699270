import { createApp } from 'vue'
import App from './App.vue';
import router from './router'
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';
import Vant from 'vant';
import 'vant/lib/index.css';
import "amfe-flexible";
import VConsole from "vconsole";
import TRTCCalling from 'trtc-calling-js';
import globalConfig from "./config/index";
import config from 'src/config'
// 神策
import sensors from "./sensors"
if(config.isDebug)
{
    new VConsole();
}
const store = createPinia()
store.use(piniaPluginPersist)

const app = createApp(App);
app.use(store);
app.use(router);
app.config.globalProperties.$sensors = sensors;

app.use(Vant).mount('#app');

