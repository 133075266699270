import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * TRTCService
 * @class
 * @version 1.0.0
 */
export default class TRTCService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return TagService
     */
     private static _instance: TRTCService;

    /**
     * 实例
     * @static
     * @property
     * @returns TagService
     */
    public static get instance(): TRTCService
    {
        if(!this._instance)
        {
            this._instance = new TRTCService();
        }
        
        return this._instance;
    }

    /**
     * 获取签名
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getUserSig(): Promise<IHttpResponse>
    {
        let response = await this.apis.getUserSig({ 
        });

        return response;
    }

    /**
     * 获取排位数
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getUserQueueNum(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getUserQueueNum({ 
            params: { id },
            loading: false
        });
        return response;
    }

    /**
     * 获取排位情况
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async checkState(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.checkState({ 
            params: { id },
            loading: false
        });
        return response;
    }
     
    /**
     * 是否人数过多
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async isBusy(): Promise<IHttpResponse>
    {
        let response = await this.apis.isBusy({ 
        loading: false
        });

        return response;
    }

    /**
     * 增加日志
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async addTrtcLog(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.addTrtcLog({ 
        loading: false,
        data:data
        });

        return response;
    }

}