import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 通用相关服务
 * @class
 * @version 1.0.0
 */
export default class GenService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return GenService
     */
     private static _instance: GenService;

    /**
     * 实例
     * @static
     * @property
     * @returns GenService
     */
    public static get instance(): GenService
    {
        if(!this._instance)
        {
            this._instance = new GenService();
        }
        
        return this._instance;
    }

    /**
     * 短信服务
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async sendSms(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.sendSms({ 
            data: data 
        });
        return response;
    }


    


}