import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 体检任务相关服务
 * @class
 * @version 1.0.0
 */
export default class PhysicalService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return PhysicalService
     */
     private static _instance: PhysicalService;

    /**
     * 实例
     * @static
     * @property
     * @returns PhysicalService
     */
    public static get instance(): PhysicalService
    {
        if(!this._instance)
        {
            this._instance = new PhysicalService();
        }
        
        return this._instance;
    }

    /**
     * 获取体检任务
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getPhysicalList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getPhysicalList({ 
            data: data 
        });
        
        return response;
    }

    /**
     * 获取体检任务详情
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getphysicalDetail(id: string | number): Promise<IHttpResponse>
    {
        let response = await this.apis.getphysicalDetail({ 
            params: {
                id
            } 
        });
        
        return response;
    }

    /**
     * 取消预约
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async cancelReserve(id: string | number): Promise<IHttpResponse>
    {
        let response = await this.apis.cancelReserve({ 
            params: {
                id
            } 
        });
        
        return response;
    }

    /**
     * 获取排期
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getSchedules(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getSchedules({ 
            data
        });
        
        return response;
    }

    /**
     * 修改排期
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async changeReserve(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.changeReserve({ 
            data
        });
        
        return response;
    }

    /**
     * 预约体检
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async reservePackage(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.reservePackage({ 
            data
        });
        
        return response;
    }

    /**
     * 获取报告列表
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getReportList(): Promise<IHttpResponse>
    {
        let response = await this.apis.reportList({ 
        });
        
        return response;
    }

    /**
     * 获取报告详情
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getReportDetail(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.reportDetail({
            responseType:"blob",
            data:{id}
        });
        
        return response;
    }

    /**
     * 验证是否日期
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async validateVacation(): Promise<IHttpResponse>
    {
        let response = await this.apis.validateVacation({
           
        });
        
        return response;
    }


}