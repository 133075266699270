import { useRoute } from 'vue-router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/login.vue';
import Home from '@/views/home.vue';
import Questionnaire from '@/views/questionnaire.vue';
import Physical from '@/views/physical.vue';
import My from '@/views/my.vue';
import Detail from '@/views/detail.vue';
import OrgList from '@/views/org-list.vue';
import Sucess from '@/views/sucess.vue';
import UpdatePwd from '@/views/updatePwd.vue';
import ReportList from '@/views/report-list.vue';
import ReportDetail from '@/views/report-detail.vue';
import Calling from '@/views/calling.vue';
import QuestionnaireSuccess from '@/views/questionnaire-success.vue';
import Sign from '@/views/sign.vue';
import faceVerify from '@/views/face-verify.vue';
import fileNotice from '@/views/file/notice.vue';
import fileChildren from '@/views/file/children.vue';
import fileThird from '@/views/file/third.vue';
import Countersign from '@/views/countersign.vue';
import { useTRTCStore } from 'src/store';
import {onMounted, reactive, watch, nextTick, ref, onUnmounted} from "vue";
import sensors from "../sensors";

const routes: Array<RouteRecordRaw> =
[
    {
        path: '/',
        name: 'login',
        component: Login,
        meta:{
            pageName: "账号登录",
            title: "登录页"
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta:{
            pageName: "首页",
            title: "首页"
        }
    },
    {
        path: '/face/verify',
        name: 'face-verify',
        component: faceVerify,
        meta:{
            pageName: "人脸核身",
            title: "视频问卷-人脸识别结果页"
        }
    },
    {
        path: '/countersign',
        name: 'countersign',
        component: Countersign,
        meta:{
            pageName: "补签",
            title: "视频问卷-身份核实"
        }
    },
    {
        path: '/reportList',
        name: 'reportList',
        component: ReportList,
        meta:{
            pageName: "报告列表",
            title: "体检报告列表"
        }
    },
    {
        path: '/report/detail',
        name: 'reportDetail',
        component: ReportDetail,
        meta:{
            pageName: "报告详情",
            title: "体检报告详情"
        }
    },
    {
        path: '/questionnaire-success',
        name: 'questionnaireSuccess',
        component: QuestionnaireSuccess,
        meta:{
            pageName: "提交成功",
            title: "视频问卷-问卷任务提交成功页"
        }
    },
    {
        path: '/sign',
        name: 'sign',
        component: Sign,
        meta:{
            pageName: "签名",
            title: "视频问卷-签字确认"
        }
    },
    {
        path: '/updatePwd',
        name: 'updatePwd',
        component: UpdatePwd,
        meta:{
            pageName: "修改密码",
            title: "修改密码"
        }
    },
    {
        path: '/physical',
        name: 'physical',
        component: Physical,
        meta:{
            pageName: "体检任务",
            title: "体检任务"
        }
    },
    {
        path: '/my',
        name: 'my',
        component: My,
        meta:{
            pageName: "我的",
            title: "我的"
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail,
        meta:{
            pageName: "预约详情",
            title: "预约详情"
        }
    },
    {
        path: '/org',
        name: 'org',
        component: OrgList,
        meta:{
            pageName: "机构选择",
            title: "机构选择"
        }
    },
    {
        path: '/sucess',
        name: 'sucess',
        component: Sucess,
        meta:{
            title: "预约成功页"
        }
        
    },
    {
        path: '/calling',
        name: 'calling',
        component: Calling,
        meta:{
            title: "视频问卷"
        }
    },
    {
        path: '/fileNotice',
        name: 'fileNotice',
        component: fileNotice,
        meta:{
            pageName: "个人信息处理规则告知书",
            title: "个人信息处理规则告知书"
        }
    },
    {
        path: '/fileChildren',
        name: 'fileChildren',
        component: fileChildren,
        meta:{
            pageName: "儿童个人信息保护政策",
            title: "儿童个人信息保护政策"
        }
    },
    {
        path: '/fileThird',
        name: 'fileThird',
        component: fileThird,
        meta:{
            pageName: "《向第三方提供及境外接收方清单》",
            title: "向第三方提供及境外接收方清单"
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});
router.beforeEach((to, from, next) =>
{
    if(to.meta.title)
    {
        document.title = to.meta.title+"";
    }
    
    next();
});

router.afterEach((to,from) => {
	nextTick(() => {
		sensors.quick("autoTrackSinglePage");
	});
});

export default router
