const globalSetting = (<any>window).globalSetting;

const globalConfig =
{
    server:  globalSetting.apiServer,
    SDKAppID:  globalSetting.SDKAppID,
    signalrUrl:  globalSetting.signalrUrl,
    sensorsConfig:  globalSetting.sensorsConfig,
    isDebug:  globalSetting.isDebug,
    questionnaireConfig:  globalSetting.questionnaireConfig,
};

export default globalConfig;
