
import globalConfig from "../config/index";
const apiUrl = globalConfig.server;

export default
{
    // 登录授权相关服务
    login:                      `${apiUrl}/aia/clientAuth/login`,                                               // 客户端登录
    sendSms:                    `${apiUrl}/aia/sms/send`,                                                       // 发送短信
    // 注册
    register:                   `${apiUrl}/aia/member/client/register`,                                         // 客户端注册
    updatePassword:             `${apiUrl}/aia/member/client/updatePassword`,                                   // 客户端修改密码

    // 任务数据
    getTaskInfo:                `${apiUrl}/aia/member/client/getTaskInfo`,                                      // 客户端获取核保任务数据
    getMembers:                 `${apiUrl}/aia/member/client/getMembers`,                                       // 客户端获取核保人员数据
    getQuestionnaireTaskPage:   `${apiUrl}/aia/member/client/getQuestionnaireTaskPage`,                         // 获取我的问卷任务分页
    getPhysicalTaskPage:        `${apiUrl}/aia/member/client/getPhysicalTaskPage`,                              // 获取我的体检任务分页
    getUserSig:                `${apiUrl}/aia/trtc/getUserSig`,                                                 // 获取trtc签名
    getUserQueueNum:            `${apiUrl}/trtc/userQueue/getState`,                                         // 获取排位值
    isBusy:                     `${apiUrl}/trtc/userQueue/isBusy`,                                             // 判断是人数过多
    checkState:                  `${apiUrl}/trtc/userQueue/checkState`,                                          // 获取排位请求
    getphysicalDetail:          `${apiUrl}/aia/physicalTask/client/get`,                                        // 客户端获取体检任务详细
    cancelReserve:              `${apiUrl}/aia/physicalTask/client/cancelReserve`,                              // 客户端取消预约
    changeReserve:              `${apiUrl}/aia/physicalTask/client/changeReserve`,                              // 客户端改约体检
    reservePackage:             `${apiUrl}/aia/physicalTask/client/toReserve`,                                  // 预约体检
    validateVacation:           `${apiUrl}/aia/physicalTask/client/vacation`,                                  // 节假日
    getQuestionnaireList:       `${apiUrl}/aia/questionnaire/list`,                                             // 问卷题目列表

    getPhysicalList:            `${apiUrl}/aia/proxy/getPhysicalTaskPage`,              // 获取体检任务
    getMemberList:              `${apiUrl}/aia/proxy/getMemberPage`,                    // 获取我的客户
    getSchedules:               `${apiUrl}/aia/physicalHospital/getSchedule`,            // 获取排期
    getPackageHospitals:        `${apiUrl}/aia/physicalHospital/getPackageHospitals`,   // 获取套餐支持的体检机构
    locationCity:               `${apiUrl}/aia/region/cities`,                          // 获取切换定位城市
    questionnaireTaskSign:      `${apiUrl}/aia/questionnaireTask/client/sign`,          // 问卷签名
    reportList:                 `${apiUrl}/aia/physicalReport/client/list`,             // 报告列表
    reportDetail:               `${apiUrl}/aia/physicalReport/client/get`,              // 查看报告
    downloadReportUrl:          `${apiUrl}/aia/physicalReport/client/download`,         // 下载报告地址
    faceVerifyLogin:            `${apiUrl}/aia/faceVerify/client/web/login`,              //   启动 H5 人脸核身
    selectFaceVerify:          `${apiUrl}/aia/faceVerify/client/queryfacerecord`,        //  人脸核身查询
    questionnaireTaskCalling:  `${apiUrl}/aia/questionnaireTask/client/calling`,        //  客户端呼叫
    getVideoPhoth:              `${apiUrl}/aia/questionnaireTask/client/getVideoPhoth`, //  客户端签名获取非身份证的视频拍照
    refreshAuth:                `${apiUrl}/aia/clientAuth/refresh`, //  客户端签名获取非身份证的视频拍照
    addTrtcLog:                  `${apiUrl}/trtc/trtcLog/add`, //  TRTC日志

};
