import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import socketClient from 'src/utils/socket-client';

// 使用setup模式定义
export const useTRTCStore = defineStore('trtc', () => {
    // socket实例
    let socket = ref(null);

    // 初始化Socket
    function initSocket()
    {
        const resp = new socketClient("");
        socket.value = resp;
        return socket;
    }

    return {
        socket,
        initSocket
    };

},{
    persist: {
        enabled: true,
        strategies: [
          {
            storage: localStorage,
            paths: []
          }
        ]
    }
});